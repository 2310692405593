import { forwardRef, memo, useContext, useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { fr } from 'date-fns/locale'

import { ButtonPrimary, Panel, ButtonSecondary, InputError } from 'ui/atoms'
import { Field } from 'ui/atoms/Field'
import { SvgClock, SvgCalendar } from 'assets/svg'
import { UnderlinedInput } from 'ui/atoms/UnderLinedInput'
import { UnderLinedSelect } from 'ui/atoms/UnderLinedSelect'
import {
  getConfigurations,
  getExperiment,
  getPultrusionDies,
  getThemes,
  getUsers,
  postExperiment,
  putExperiment
} from 'modules/lab/services'
import i18n from 'modules/lab/i18n'

import { InformationsPanelFormValues } from './DescriptionTab.type'
import { ApiDate, ApiTime, Nullable, ToastProps } from 'interfaces'
import { Experiment, ExperimentDto, Machine } from 'interfaces/api/lab'
import { format, isValid, parse } from 'date-fns'
import { DATE_FORMAT, TIME_FORMAT, ToastType } from 'core/constants'
import { InputType } from 'ui/molecules/TextInput'
import { UiContext } from 'contexts/ui.context'
import { SpinnerLoaderMask } from 'ui/molecules'
import { FetchResult } from 'utils/fetch'
import { LabContext } from 'modules/lab/contexts'

registerLocale('fr', fr)

const InformationsPanel = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const { control, setValue, handleSubmit, watch } = useForm<InformationsPanelFormValues>()
    const [errors, setErrors] = useState<Nullable<Record<keyof ExperimentDto, string[]>>>(null)
    const { updateToast } = useContext(UiContext)
    const { experimentId } = useParams()
    const navigate = useNavigate()
    const { updateReference } = useContext(LabContext)

    const queryClient = useQueryClient()

    const experimentQuery: UseQueryResult<FetchResult<Experiment>> = useQuery(
      ['lab.experiment', experimentId],
      experimentId ? () => getExperiment(experimentId) : () => null,
      {
        onSuccess: (data) => {
          resetForm(data?.data ?? null)
          updateReference(data.data?.reference)
        }
      }
    )
    const usersQuery = useQuery('lab.users', () => getUsers())
    const themesQuery = useQuery('lab.themes', () => getThemes())
    const pultrusionsDiesQuery = useQuery('lab.pultrusions', () => getPultrusionDies())
    const configurationsQuery = useQuery('lab.configurations', () => getConfigurations())

    const defaultConfiguration = configurationsQuery.data?.data?.find((c) => c.is_active)
    const selectedMachine = configurationsQuery.data?.data?.find((c) => c.id == watch('configuration_id'))?.machine

    const experimentCreation = useMutation(postExperiment, {
      onMutate: () => setErrors(null),
      onSuccess: (data) => {
        updateToast({
          content: 'Essai crée avec succès',
          displayed: true,
          type: ToastType.SUCCESS,
          hideCallback: () =>
            updateToast({
              displayed: false
            })
        })
        navigate(`/experiments/${data.data?.id}`)
        queryClient.invalidateQueries('experiment')
      },
      onError: (e: { errors?: Record<keyof ExperimentDto, string[]> }) => setErrors(e.errors ?? null)
    })

    const experimentUpdate = useMutation(
      (params: { id: string; data: ExperimentDto }) => putExperiment(params.id, params.data),
      {
        onMutate: () => setErrors(null),
        onSuccess: () => {
          updateToast({
            content: 'Essai  modifié avec succès',
            displayed: true,
            type: ToastType.SUCCESS,
            hideCallback: () =>
              updateToast({
                displayed: false
              })
          })
          navigate(`/experiments`)
          queryClient.invalidateQueries('experiment')
        },
        onError: (e: { errors?: Record<keyof ExperimentDto, string[]> }) => setErrors(e.errors ?? null)
      }
    )
    const createExperiment = ({
      configuration_id,
      details,
      finish_time,
      pultrusion_die_id,
      realized_at,
      reference,
      speed,
      start_time,
      theme_id,
      user_id
    }: InformationsPanelFormValues) => {
      experimentCreation.mutate({
        configuration_id,
        details,
        finish_time: finish_time && isValid(finish_time) ? (format(finish_time, TIME_FORMAT) as ApiTime) : null,
        pultrusion_die_id,
        realized_at: realized_at && isValid(realized_at) ? (format(realized_at, DATE_FORMAT) as ApiDate) : null,
        reference: `${selectedMachine}${reference}`,
        speed,
        start_time: start_time && isValid(start_time) ? (format(start_time, TIME_FORMAT) as ApiTime) : null,
        theme_id,
        user_id
      })
    }
    const updateExperiment = ({
      configuration_id,
      details,
      finish_time,
      pultrusion_die_id,
      realized_at,
      reference,
      speed,
      start_time,
      theme_id,
      user_id
    }: InformationsPanelFormValues) => {
      experimentUpdate.mutate({
        id: experimentId ?? '',
        data: {
          configuration_id,
          details,
          finish_time: finish_time && isValid(finish_time) ? (format(finish_time, TIME_FORMAT) as ApiTime) : null,
          pultrusion_die_id,
          realized_at: realized_at && isValid(realized_at) ? (format(realized_at, DATE_FORMAT) as ApiDate) : null,
          reference: selectedMachine + reference,
          speed,
          start_time: start_time && isValid(start_time) ? (format(start_time, TIME_FORMAT) as ApiTime) : null,
          theme_id,
          user_id
        }
      })
    }

    const isLoading = [
      experimentQuery.isFetching,
      usersQuery.isFetching,
      themesQuery.isFetching,
      pultrusionsDiesQuery.isFetching,
      configurationsQuery.isFetching,
      experimentCreation.isLoading,
      experimentUpdate.isLoading
    ].includes(true)
    const handleOnSubmit: SubmitHandler<InformationsPanelFormValues> = (data) => {
      experimentQuery.data ? updateExperiment(data) : createExperiment(data)
    }

    /**
     * Set default configuration by default
     */
    useEffect(() => {
      if (experimentQuery.data) {
        return
      }
      setValue('configuration_id', defaultConfiguration?.id)
    }, [defaultConfiguration, experimentQuery.data])

    /**
     * Update field based on experiment object
     */
    const resetForm = (data: Nullable<Experiment>) => {
      if (!data) {
        return
      }
      const {
        configuration_id,
        details,
        finish_time,
        pultrusion_die_id,
        realized_at,
        reference,
        speed,
        start_time,
        theme_id,
        user_id
      } = data
      const matches = reference.match(/^(RO|PX)(?<reference>\d{6}_\d{1,2})$/)
      const referenceWithoutPrefix = matches?.groups?.reference
      setValue('configuration_id', configuration_id)
      setValue('pultrusion_die_id', pultrusion_die_id)
      setValue('user_id', user_id)
      setValue('theme_id', theme_id)
      setValue('details', details)
      setValue('finish_time', finish_time ? parse(finish_time, TIME_FORMAT, new Date()) : null)
      setValue('realized_at', parse(realized_at, DATE_FORMAT, new Date()))
      setValue('reference', referenceWithoutPrefix ?? '')
      setValue('speed', speed)
      setValue('start_time', parse(start_time, TIME_FORMAT, new Date()))
    }

    const handleReset = () => {
      updateToast({
        displayed: true,
        type: 'error',
        content: 'Les modifications on été annulées.'
      } as ToastProps)
      resetForm(experimentQuery.data?.data ?? null)
    }
    const machine = selectedMachine ?? defaultConfiguration?.machine
    const machinePrefixValue = machine ? (machine === Machine.PX ? 'Px' : 'Ro') : ''

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)} ref={ref}>
        <Panel title={i18n.descriptionTab.informationsPanel.title}>
          <SpinnerLoaderMask spinning={isLoading}>
            <div className='grid grid-cols-3 gap-6 justify-between'>
              <Field
                className='justify-self-start'
                label={i18n.descriptionTab.informationsPanel.fieldLabels.configuration}
              >
                <Controller
                  control={control}
                  name='configuration_id'
                  render={({ field }) => (
                    <UnderLinedSelect
                      {...field}
                      className='w-48'
                      errors={errors?.configuration_id ?? null}
                      options={configurationsQuery.data?.data?.map((d) => ({ label: d.name, value: d.id })) ?? []}
                      placeholder='Configuration'
                    />
                  )}
                />
              </Field>
              <Field
                className='justify-self-center'
                label={i18n.descriptionTab.informationsPanel.fieldLabels.pultrusionDie}
              >
                <Controller
                  control={control}
                  name='pultrusion_die_id'
                  render={({ field }) => (
                    <UnderLinedSelect
                      {...field}
                      className='w-48'
                      errors={errors?.pultrusion_die_id ?? null}
                      options={pultrusionsDiesQuery.data?.data?.map((d) => ({ label: d.label, value: d.id })) ?? []}
                      placeholder='Nom_filière'
                    />
                  )}
                />
              </Field>
              <Field className='justify-self-end' label={i18n.descriptionTab.informationsPanel.fieldLabels.speed}>
                <Controller
                  control={control}
                  name='speed'
                  render={({ field }) => (
                    <UnderlinedInput
                      className='w-48'
                      errors={errors?.speed ?? null}
                      onChange={field.onChange}
                      placeholder='Chiffre'
                      type={InputType.NUMBER}
                      value={field.value}
                    />
                  )}
                />
              </Field>
              <Field className='justify-self-start' label={i18n.descriptionTab.informationsPanel.fieldLabels.reference}>
                <div className='flex overflow-hidden w-48'>
                  <span className='pb-2 mr-2 w-8 text-sm'>{machinePrefixValue}</span>
                  <Controller
                    control={control}
                    name='reference'
                    render={({ field }) => (
                      <UnderlinedInput
                        className='flex flex-1'
                        errors={errors?.reference ?? null}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                </div>
              </Field>
              <Field className='justify-self-center' label={i18n.descriptionTab.informationsPanel.fieldLabels.user}>
                <Controller
                  control={control}
                  name='user_id'
                  render={({ field }) => (
                    <UnderLinedSelect
                      {...field}
                      className='w-48'
                      errors={errors?.user_id ?? null}
                      options={usersQuery.data?.data?.map((d) => ({ label: d.name, value: d.id })) ?? []}
                      placeholder='Nom du responsable'
                    />
                  )}
                />
              </Field>
              <Field className='justify-self-end' label={i18n.descriptionTab.informationsPanel.fieldLabels.theme}>
                <Controller
                  control={control}
                  name='theme_id'
                  render={({ field }) => (
                    <UnderLinedSelect
                      {...field}
                      className='w-48'
                      errors={errors?.theme_id ?? null}
                      options={themesQuery.data?.data?.map((d) => ({ label: d.label, value: d.id })) ?? []}
                      placeholder='Nom du thème'
                    />
                  )}
                />
              </Field>
              <Field
                className='justify-self-start'
                label={i18n.descriptionTab.informationsPanel.fieldLabels.realizedAt}
              >
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  name='realized_at'
                  render={({ field }) => (
                    <div className='flex flex-col'>
                      <DatePicker
                        calendarStartDay={1}
                        customInput={
                          <input
                            className={`${
                              errors?.realized_at && errors?.realized_at[0] ? 'border-b-[red]' : 'border-b-pinkish-grey'
                            } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
                          />
                        }
                        dateFormat={DATE_FORMAT}
                        locale='fr'
                        onChange={(date) => field.onChange(date)}
                        placeholderText='--:--'
                        selected={field.value}
                        showPopperArrow={false}
                        wrapperClassName='w-24'
                      />
                      <InputError errors={errors?.realized_at ?? null} />
                    </div>
                  )}
                />
                <img className='my-1.5 mr-16 ml-3 w-5 h-5 text-silver' src={SvgCalendar} />
              </Field>
              <Field
                className='justify-self-center'
                label={i18n.descriptionTab.informationsPanel.fieldLabels.startTime}
              >
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  name='start_time'
                  render={({ field }) => (
                    <div className='flex flex-col w-24'>
                      <DatePicker
                        customInput={
                          <input
                            className={`${
                              errors?.start_time && errors?.start_time[0] ? 'border-b-[red]' : 'border-b-pinkish-grey'
                            } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
                          />
                        }
                        dateFormat='HH:mm'
                        onChange={(date) => field.onChange(date)}
                        placeholderText='--:--'
                        selected={field.value}
                        showPopperArrow={false}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        timeCaption='Heure'
                        timeFormat='HH:mm'
                        timeIntervals={30}
                        wrapperClassName='w-full'
                      />
                      <InputError errors={errors?.start_time ?? null} />
                    </div>
                  )}
                />
                <img className='my-1.5 mr-16  ml-3 w-5 h-5 text-silver' src={SvgClock} />
              </Field>
              <Field className='justify-self-end' label={i18n.descriptionTab.informationsPanel.fieldLabels.finishTime}>
                <Controller
                  control={control}
                  name='finish_time'
                  render={({ field }) => (
                    <div className='flex flex-col'>
                      <DatePicker
                        customInput={
                          <input
                            className={`${
                              errors?.finish_time && errors?.finish_time[0] ? 'border-b-[red]' : 'border-b-pinkish-grey'
                            } pb-2 text-sm text-warm-grey border-b outline-none h-8 w-inherit bg-white`}
                          />
                        }
                        dateFormat='HH:mm'
                        onChange={(date) => field.onChange(date)}
                        placeholderText='--:--'
                        selected={field.value}
                        showPopperArrow={false}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        timeCaption='Heure'
                        timeFormat='HH:mm'
                        timeIntervals={30}
                        wrapperClassName='w-24'
                      />
                      <InputError errors={errors?.finish_time ?? null} />
                    </div>
                  )}
                />
                <img className='my-1.5 mr-16  ml-3 w-5 h-5 text-silver' src={SvgClock} />
              </Field>
              <Field className='col-span-3' label={i18n.descriptionTab.informationsPanel.fieldLabels.details}>
                <Controller
                  control={control}
                  name='details'
                  render={({ field }) => (
                    <textarea
                      className='p-4 w-full h-36 border border-pinkish-grey'
                      onChange={field.onChange}
                      value={field.value ?? ''}
                    />
                  )}
                />
              </Field>
            </div>
            <div className='flex flex-row justify-center mt-10'>
              <ButtonSecondary className='px-14 mr-40' onClick={handleReset} type='button'>
                Annuler
              </ButtonSecondary>
              <ButtonPrimary className='px-14' type='submit'>
                Valider
              </ButtonPrimary>
            </div>
          </SpinnerLoaderMask>
        </Panel>
      </form>
    )
  })
)

export { InformationsPanel }
