import { SvgEye } from 'assets/svg'
import { UiContext } from 'contexts/ui.context'
import { Nullable } from 'interfaces'
import { process_parameter } from 'modules/lab/schemas'
import { FC, useContext } from 'react'
import { SvgInjector } from 'ui/atoms/SvgInjector'
import { z } from 'zod'
import { TableTd } from './TableTd'

const OvensTable: FC<{
  ovenTemperatures: Nullable<number>[]
  reference: string
}> = ({ ovenTemperatures, reference }) => {
  return (
    <div className='flex flex-col p-8 pt-0'>
      <div className='flex flex-row justify-center items-center py-8 border-b border-pinkish-grey'>
        <span className='text-xl font-semibold text-black'>Fours - </span>
        <span className='mr-8 text-xl text-black'>Essai {reference}</span>
      </div>
      <div className='p-8'>
        <table className='w-full'>
          <thead>
            <tr>
              <th className='pr-4 text-sm font-medium text-left text-black'>Numéro</th>
              <th className='text-sm font-medium text-left text-black'>Température (°C)</th>
            </tr>
          </thead>
          <tbody>
            {ovenTemperatures
              .filter((ot) => ot !== null)
              .map((ot, i) => (
                <tr key={i}>
                  <td className='py-4 text-sm text-left text-black'>{`${i + 1}`.padStart(2, '0')}</td>
                  <td className='py-4 text-sm text-left text-black'>{ot} °C</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ProcessParameterTd: FC<{
  matrix: Record<string, boolean>
  reference: string
  data: z.infer<typeof process_parameter>
}> = ({ matrix, reference, data }) => {
  const { updateModal } = useContext(UiContext)
  const handleOnClick = () => {
    updateModal({
      content: <OvensTable ovenTemperatures={data?.oven_temperatures ?? []} reference={reference} />,
      displayed: true
    })
  }
  return (
    <>
      {matrix.wire_count && <TableTd className='text-left'>{data?.wire_count}</TableTd>}
      {matrix.has_conformation && (
        <TableTd className='text-left'>{data ? (data.has_conformation ? 'Oui' : 'Non') : '-'}</TableTd>
      )}
      {matrix.oven_temperatures && (
        <TableTd className='text-left'>
          <button className='flex justify-center items-center py-2 px-1 mr-2' onClick={handleOnClick} type='button'>
            <SvgInjector className='inline-block w-4 h-4  text-warm-grey' src={SvgEye} />
          </button>
          {data?.oven_temperatures.length}
        </TableTd>
      )}
      {matrix.impregnation_mode && <TableTd className='text-left'>{data?.impregnation_mode}</TableTd>}
      {matrix.is_stable && <TableTd className='text-left'>{data ? (data.is_stable ? 'Oui' : 'Non') : '-'}</TableTd>}
    </>
  )
}

export { ProcessParameterTd }
